<template lang="pug">
	.list(v-if='list')
		.item(v-for="item in list" :class="{active:isActive(item)}" @click="select(item)")
			.value( v-html="item.title")
			.radio
</template>
<script>
export default {
    name: 'RadioItemCardList',
    props: {
        list: {
            type: Array,
        },
        defaultValue: {
            type: String,
        },
        value: {
            required: true,
        },
    },
    created() {
        this.checkValue();
    },
    watch: {
        list() {
            this.checkValue();
        },
    },
    methods: {
        isActive(item) {
            return item.id === this.value;
        },
        select(item) {
            if (!item?.id) return;
            this.$emit('input', item.id);
        },
        checkValue() {
            const selectFirst = () => {
                let item = this.list.find(itm => itm.value.toString() === this.defaultValue);
                if (!item) item = this.list.find(itm => 1);
                this.select(item);
            };
            if (!this.value) {
                selectFirst();
                return;
            }
            const list = this.list.map(itm => itm.id);
            if (!list.includes(this.value)) {
                selectFirst();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.list {
    margin-bottom: 20px;
    display: flex;
    gap: 20px;

    .item {
        background: var(--grayscale-bg-dark-back);
        border-radius: 8px;
        border: 2px solid transparent;
        padding: 25px 17px;
        font-size: 18px;
        font-weight: 600;
        flex: 1;
        cursor: pointer;
        position: relative;
        transition: all 0.5s ease;

        .radio {
            border: 2px solid var(--brand-primary);
            position: absolute;
            right: 10px;
            top: 10px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            transition: all 0.5s ease;
        }
        &:hover {
            box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.08);
        }
        &.active {
            border-color: var(--brand-gradient-primary);
            background: var(--primary-light-color);

            .radio {
                border-width: 5px;
            }
        }
    }
}
</style>
