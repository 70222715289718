<template lang="pug">
	.toggle(:class="{active:active,disable:disable}" @click="$emit('input',!active)")
		.circle
</template>
<script>
export default {
    props: ['value', 'disable'],
    computed: {
        active() {
            return !!this.value;
        },
    },
};
</script>

<style scoped lang="scss">
.toggle {
    --height: 24px;
    --border: 3px;

    width: calc(var(--height) * 2);
    height: var(--height);
    border: var(--border) solid transparent;
    background: var(--theme-color-1);
    border-radius: var(--height);
    cursor: pointer;
    opacity: 0.7;
    position: relative;
    transition: var(--animation-time-short);

    &.disable {
        opacity: 0.5;
        pointer-events: none;
    }
    .circle {
        width: calc(var(--height) - var(--border) * 2);
        background: var(--button-bg-color);
        border-radius: 50%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        transition: var(--animation-time-short);
    }
    &.active {
        opacity: 1;

        .circle {
            left: calc(100% - var(--height) + var(--border) * 2);
        }
    }
}
</style>
