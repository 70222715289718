<template lang="pug">
	extends ./table.pug

	block tableTH

		template(v-slot:cell(id)='data')
			p.mb-2.b3(v-if="data.item.user?.id") {{ data.item.user?.id }}
			p.mb-0.b4.text-grey.text-nowrap(v-if="data.item.createdAt") {{ data.item.createdAt | dateMMTDTYYYY }}

		template(v-slot:cell(subscriptionsTotal)='data')
			p.mb-2.b3 {{ data.item.subscriptionsTotal }}

		template(v-slot:cell(subscriptions30Days)='data')
			p.mb-2.b3 {{ data.item.subscriptions30Days }}

		template(v-slot:cell(merchantIncome)='data')
			p.mb-2.b3(v-for="item in incomeFormat(data.item.merchantIncomeTotal)" v-html="item")

		template(v-slot:cell(user)='data')
			p.mb-1
				user-short-data(v-if='data.item.user' :item='data.item.user')
				span.text-warning(v-else) {{ $t('user.unknow') }}
			p.mb-0.b4.text-grey.text-nowrap(v-if="data.item.user?.emailPersonal") {{ data.item.user?.emailPersonal }}

		template(v-slot:empty)
			.empty
				.title 😢
				.description {{ $t('partners.empty') }}

</template>

<script>
import { bus } from '@/main.js';
import { mapGetters, mapActions } from 'vuex';
import TableMixin from './TableMixin';
import TableSearch from './TableSearch';
import TableShowMore from './TableShowMore';
import UserShortData from '@/components/Common/UserShortData';
import ModalTooltip from '@/components/Modals/ModalTooltip';
import { SERVICE_STRING } from '@/utils/string';
import { CURRENCY, Money } from '@/models/money';
import InfoTooltip from '@/components/Common/InfoTooltip';
import IconClose from '@/components/Common/IconClose';

export default {
    name: 'PartnersTable',
    mixins: [TableMixin],
    components: {
        TableSearch,
        TableShowMore,
        UserShortData,
        ModalTooltip,
        InfoTooltip,
        IconClose,
    },
    props: {
        role: {
            type: String,
            default: 'item',
        },
        filters: {
            type: Object,
            default: () => ({}),
        },
        type: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            noLocalSorting: true,
            sortBy: 'createdAt',
            searchKeys: ['createdAt'],
            sorting: ctx => {
                let data = {};
                data[`order[${ctx.sortBy}]`] = 'desc';
                data[`id`] = this.project.id;

                this.isbusy = true;
                this.getItems(data);
            },
            fields: [
                {
                    key: 'id',
                    label: this.$t('partners.id'),
                    sortable: true,
                },
                {
                    key: 'user',
                    label: this.$t('partners.partner'),
                },
                {
                    key: 'subscriptionsTotal',
                    label: this.$t('partners.totalPartners'),
                },
                {
                    key: 'subscriptions30Days',
                    label: this.$t('partners.totalPartners30Days'),
                },
                {
                    key: 'merchantIncome',
                    label: this.$t('partners.income'),
                },
            ],
            items: [],
        };
    },

    mounted() {
        this.getItems = _.debounce((data = {}) => {
            if (this.filters.query && this.filters.query.value != null) {
                this.filters.query.value = this.filters.query.value.replace(/^\@/g, '');
            }

            this.get(_.extend(this.parseFilters(), { page: this.currentPage, itemsPerPage: this.perPage }, data))
                .then(v => {
                    if (this.currentPage == 1) this.items = [];

                    this.items = this.items.concat(v);
                    this.isbusy = false;
                    this.$emit('onGetItems', v);
                })
                .catch(v => (this.isbusy = false));
        }, 500);

        bus.$on('tableFollowersRefresh', query => {
            this.searching(query);
        });

        this.searching();
    },
    created() {
        this.searching = _.debounce(query => {
            this.sorting(this);
        }, 400);
    },
    destroyed() {
        bus.$off('tableFollowersRefresh');
        bus.$off('tableFollowersExport');
        this.$store.commit('finance/setSubscriptionsTotal', 0);
    },
    computed: {
        ...mapGetters({
            project: 'project/opened',
            totalItems: 'finance/subscriptionsTotal',
            isPending: 'finance/isPendingSubcriptions',
        }),
        serviceString() {
            return SERVICE_STRING;
        },
        currency() {
            return CURRENCY;
        },
    },
    methods: {
        ...mapActions({
            get: 'project/getPartnersList',
        }),

        incomeFormat(moneyCollection) {
            if (!moneyCollection) return;
            return Object.values(moneyCollection).map(val => new Money(val).priceWithCurrency());
        },
        localSorting(ctx) {},
    },
};
</script>

<style lang="scss" scoped>
.badge {
    padding-top: 4px;
    padding-bottom: 4px;
}
.b3 {
    white-space: nowrap;
}
.empty {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 15px;

    .title {
        font-size: 32px;
    }
    .description {
        font-size: 16px;
    }
}
</style>
