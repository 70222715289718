<template lang="pug">
	.average(:class="{isVertical:isVertical}")
		.item(v-for="(item,index) in valueFormatted")
			.title(v-html="$t('partners.average.itemTitle.'+index)")
			.line(v-for="(price,period) in item")
				.label(v-html="$t('partners.average.itemType.'+period)")
				.value(v-html="formatPrice(price)")
</template>

<script>
import { Money } from '@/models/money';

export default {
    name: 'PartnersAverageIncome',
    props: ['value', 'isVertical'],
    methods: {
        formatPrice(price) {
            return new Money(price).priceWithCurrency();
        },
    },
    computed: {
        valueFormatted() {
            let val2 = {};
            for (const type in this.value) {
                for (const k in this.value[type]) {
                    const currency = this.value[type][k].currency;
                    if (!val2[currency]) val2[currency] = {};
                    if (!val2[currency][type]) val2[currency][type] = {};
                    val2[currency][type] = this.value[type][k];
                }
            }
            return val2;
        },
    },
};
</script>

<style scoped lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.average {
    display: flex;
    gap: 15px;

    &.isVertical {
        flex-direction: column;
    }
    @include respond-below(sm) {
        flex-direction: column;
    }
    .item {
        border-radius: 8px;
        border: 1px solid var(--border-item-color-line);
        flex: 1;
        padding: 20px;

        .title {
            font-size: 15px;
            margin-bottom: 10px;
        }
        .line {
            display: flex;
            gap: 10px;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 14px;

            &:last-child {
                margin-bottom: 0;
            }
            .label {
                color: var(--grey-text-color);
            }
            .value {
                padding: 5px 10px;
                background: var(--bg-back);
                border-radius: 8px;
                font-size: 15px;
            }
        }
    }
}
</style>
